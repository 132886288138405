import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useNuxtApp } from '#imports'
import { type User } from '@/types/user.interface'
import { type Organization } from '@/types/organization.interface'
import { useNotifications, useI18n } from '@ha/components-v3'
import getDefaultUser from '@/helpers/user/getDefaultUser'

export default defineStore('storeUser', () => {
  const user = ref<User>(getDefaultUser())
  const organizations = ref<Organization[]>([])
  const isLoading = ref<boolean>(false)
  const isDisconnecting = ref<boolean>(false)
  const isUserConnected = computed(
    () => !isLoading.value && !!user.value.userId
  )

  const { $apiV5, $apiOAuth } = useNuxtApp()
  const { notifyError } = useNotifications()
  const i18n = useI18n()
  const fetchUser = async () => {
    try {
      isLoading.value = true
      const response = await $apiV5('/agg/user', {
        method: 'GET'
      })
      user.value = response.user
      organizations.value = response.organizations
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layout.errorFetchUserTitle'),
        body: i18n.t('layout.errorFetchUserBody')
      })
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const logOut = async () => {
    try {
      isDisconnecting.value = true
      await $apiOAuth('/disconnect', {
        method: 'GET'
      })
      user.value = getDefaultUser()
      organizations.value = []
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layout.errorLogoutTitle'),
        body: i18n.t('layout.errorLogoutBody')
      })
      throw error
    } finally {
      isDisconnecting.value = false
    }
  }

  return {
    user,
    organizations,
    logOut,
    isLoading,
    isDisconnecting,
    fetchUser,
    isUserConnected
  }
})
