import { type User } from '@/types/user.interface'

export default (): User => ({
  userId: null,
  firstName: '',
  lastName: '',
  email: '',
  userSlug: '',
  logo: '',
  address: '',
  city: '',
  zipCode: '',
  phone: '',
  birthDate: '',
  lastSignInDate: ''
})
